import { z } from "zod";

export const geoLocationSchema = z.object({
  latitude: z.number(),
  longitude: z.number(),
});

export type GeoLocation = z.infer<typeof geoLocationSchema>;

/**
 * @deprecated
 * The legacy APIs and code patterns assume that coordinates are well understood
 * to be in a specific order of longitude, latitude.
 * This is not a good practice, and can easily lead to mistakes.
 * Use the object `GeoLocation` instead.
 */
export type LegacyGeoLocationCoordinates = [longitude: number, latitude: number];
