import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import { isDefined } from "@clipboard-health/util-ts";
import { isPlatform } from "@ionic/core";
import { Diagnostic } from "@ionic-native/diagnostic";
import { APP_V2_APP_EVENTS, logError } from "@src/appV2/lib/analytics";
import type { RadarUser } from "capacitor-radar";
import semVer from "semver";

import { getDeviceInfo } from "../lib";
import { type Worker } from "../Worker/api/types";
import {
  LocationAccuracyStatus,
  MILES_TO_METERS_MULTIPLIER,
  MIN_ANDROID_OS_WITH_ALWAYS_ALLOW_OPTION,
  UrgentShiftLocalStorage,
} from "./constant";
import { type GeoLocation, type LegacyGeoLocationCoordinates } from "./types";

export function convertToGeoLocation(
  legacyGeoLocationCoordinates: LegacyGeoLocationCoordinates
): GeoLocation {
  const [longitude, latitude] = legacyGeoLocationCoordinates;
  return { longitude, latitude };
}

/**
 * @deprecated
 * The legacy APIs and code patterns assume that coordinates are well understood
 * to be in a specific order of longitude, latitude.
 * This is not a good practice, and can easily lead to mistakes.
 * Use the object `GeoLocation` instead.
 * Do not use array coordinates for geoLocation. Use `GeoLocation` instead.
 */
export function convertToLegacyGeoLocationCoordinates(
  geoLocation: GeoLocation
): LegacyGeoLocationCoordinates {
  const { longitude, latitude } = geoLocation;
  return [longitude, latitude];
}

/**
 * @deprecated
 * The legacy APIs and code patterns assume that coordinates are well understood
 * to be in a specific order of latitude, longitude.
 * This is not a good practice, and can easily lead to mistakes.
 * Use the object `GeoLocation` instead.
 */
export function formatGeoLocationToLatitudeLongitude(geoLocation: GeoLocation): string {
  const { longitude, latitude } = geoLocation;
  return `${latitude},${longitude}`;
}

export function convertToGoogleMapsLocation(geoLocation: GeoLocation): google.maps.LatLngLiteral {
  return {
    lat: geoLocation.latitude,
    lng: geoLocation.longitude,
  };
}

export function convertFromGoogleMapsLocation(
  googleMapsLocation: google.maps.LatLngLiteral
): GeoLocation {
  return {
    latitude: googleMapsLocation.lat,
    longitude: googleMapsLocation.lng,
  };
}

interface WithinFacilityGeofenceProps {
  facilityId?: string;
  user?: RadarUser;
}

export interface PermissionUpdate {
  agentId?: string;
  isLocationServicesEnabled?: boolean;
  locationServicesPermission?: string;
  preciseLocation?: boolean;
}

export function isWithinFacilityGeofence(params: WithinFacilityGeofenceProps) {
  const { facilityId, user } = params;
  if (!isDefined(facilityId)) {
    return false;
  }

  const geofences = user?.geofences ?? [];
  return geofences.some((geofence) => geofence?.externalId === facilityId);
}

export async function isOsWithoutAlwaysAllowOption(): Promise<boolean> {
  const deviceInfo = await getDeviceInfo();
  if (deviceInfo.platform !== "android") {
    return false;
  }

  const osVersion = semVer.coerce(deviceInfo.osVersion);
  if (!osVersion) {
    return false;
  }

  return semVer.lt(osVersion, MIN_ANDROID_OS_WITH_ALWAYS_ALLOW_OPTION);
}

export async function isOsAlwaysAllowLocationPermission(permission: string): Promise<boolean> {
  if (permission === Diagnostic.permissionStatus.GRANTED) {
    return true;
  }

  if (permission !== Diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
    return false;
  }

  return await isOsWithoutAlwaysAllowOption();
}

export async function getLocationPermissionAccuracyStatus(): Promise<boolean> {
  try {
    if (!isPlatform("capacitor")) {
      return true;
    }

    const result = await Diagnostic.getLocationAccuracyAuthorization();
    if (String(result) === "false") {
      return false;
    }

    if (!result) {
      logError(APP_V2_APP_EVENTS.LOCATION_ACCURACY_ERROR, {
        error: "Location accuracy status is not available",
      });
      return true;
    }

    return result === LocationAccuracyStatus.FULL;
  } catch (error) {
    logError(APP_V2_APP_EVENTS.LOCATION_ACCURACY_ERROR, {
      error,
    });
    return true;
  }
}

export async function getCurrentLocationStatus() {
  if (!Capacitor.isNativePlatform()) {
    return {
      isLocationServicesEnabled: true,
      locationServicesPermission: Diagnostic.permissionStatus?.GRANTED ?? "granted",
      preciseLocation: true,
    };
  }

  const status = (await Diagnostic.getLocationAuthorizationStatus()) as string;
  const serviceEnabled = await Diagnostic.isLocationEnabled();
  const preciseLocation = await getLocationPermissionAccuracyStatus();
  const isAlwaysAllowedPermission = await isOsAlwaysAllowLocationPermission(status);

  const isLocationServicesEnabled = isAlwaysAllowedPermission && serviceEnabled && preciseLocation;

  return {
    isLocationServicesEnabled,
    locationServicesPermission: status,
    preciseLocation,
  };
}

export async function getPreviousLocationPermissionStatus(
  worker?: Worker
): Promise<PermissionUpdate> {
  const { value } = await Preferences.get({
    key: UrgentShiftLocalStorage.LAST_LOCATION_PERMISSION_STATUS,
  });

  const lastPermissionStatus = { ...worker?.mobile, agentId: worker?.userId };

  if (!value) {
    return lastPermissionStatus;
  }

  try {
    return JSON.parse(value) as PermissionUpdate;
  } catch (error) {
    logError(APP_V2_APP_EVENTS.ERROR_FETCHING_PREVIOUS_LOCATION_PERMISSION, {
      error,
    });
  }

  return lastPermissionStatus;
}

export async function checkIsFullLocationPermissionRequired(
  permissionUpdate: PermissionUpdate
): Promise<boolean> {
  if (!Capacitor.isNativePlatform()) {
    return false;
  }

  const { isLocationServicesEnabled } = permissionUpdate;

  if (isLocationServicesEnabled) {
    await Preferences.set({
      key: UrgentShiftLocalStorage.USER_DISABLED_FULL_LOCATION_PERMISSION,
      value: "false",
    });

    return false;
  }

  const { value: disabledPermission } = await Preferences.get({
    key: UrgentShiftLocalStorage.USER_DISABLED_FULL_LOCATION_PERMISSION,
  });
  if (disabledPermission === "true") {
    return true;
  }

  await Preferences.set({
    key: UrgentShiftLocalStorage.USER_DISABLED_FULL_LOCATION_PERMISSION,
    value: "true",
  });

  return true;
}

export function convertMetersToMiles(meters: number): number {
  return meters * MILES_TO_METERS_MULTIPLIER;
}
