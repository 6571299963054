import { isPlatform } from "@ionic/react";
import { Diagnostic } from "@ionic-native/diagnostic";

export const requestLocationAccess = async () => {
  const locationStatus = (await Diagnostic.getLocationAuthorizationStatus()) as string;

  if (locationStatus !== Diagnostic.permissionStatus.NOT_REQUESTED) {
    return;
  }

  await Diagnostic.requestLocationAuthorization();
};

export const requestFullLocationAccess = async () => {
  if (!isPlatform("capacitor")) {
    return;
  }

  await requestLocationAccess();
  await Diagnostic.switchToSettings();
};
